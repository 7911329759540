import { React, useState } from "react";
function TeamMember(props) {
    const memberCustomFields = props.profile["toolset-meta"]["team-members-group"];
    const fullName = memberCustomFields["team-member-full-name"]["raw"];
    const jobTitle = memberCustomFields["job-title"]["raw"];
    const photo = memberCustomFields["team-member-listing-photo"]["raw"];
    const phone = memberCustomFields["team-member-phone"]["raw"];
    const email = memberCustomFields["email"]["raw"];
    const memberID = props.profile.id;
    let linkURL = window.location.href+"member-detail/"+memberID;
    let qrcodeURL = "https://api.qrserver.com/v1/create-qr-code/?data="+window.location.href+"member-detail/"+memberID+"&color=black";
    const backgroundStyle = {
        backgroundImage: 'url('+photo+')',
    }
    const goToMember = () => {
        window.location = linkURL;
    }
    let firstPart = "";
    let secondPart = "";
    let atIndex = "";
    if(email.length > 35) {
        atIndex = email.indexOf('@');
        firstPart = email.slice(0, atIndex);
        secondPart = email.slice(atIndex);
    }
    return(
        <div className="aMember" onClick={goToMember}>
            <div className="aMember__photo" style={backgroundStyle}></div>
            <div className="aMember__details-wrapper">
                <div className="aMember__left-column">
                    <div>
                        <a href={linkURL}><h2 className="aMember__name">{fullName}</h2></a>
                        <p className="aMember__title">{jobTitle}</p>
                    </div>
                    <div className="aMember__ctas-wrapper">
                        <a className="aMember__phone" href={'tel:'+phone}><span>T</span>{phone}</a>  
                        {atIndex
                            ? <a className="aMember__email long-email" href={'mailto:'+email}><span>E</span>{firstPart}<br className="mobile-only"></br>{secondPart}</a>  
                            : <a className="aMember__email" href={'mailto:'+email}><span>E</span>{email}</a>  
                        }                       
                    </div>
                </div>
                <a className="qrcode-link" href={linkURL}><img alt="profile qr code" src={qrcodeURL} /></a>
            </div>
        </div>
    );
}
export default TeamMember;