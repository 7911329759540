import React, { useState, useEffect, useRef } from "react";
import TeamMember from "../components/TeamMember";

function Homepage() {
    const [profiles, setProfiles] = useState([]);
    const [DataisLoaded, setLoaded] = useState(false);
    const sortJSON = (data) => {
        return data.sort(function(a, b) {
            if(parseInt(a["toolset-meta"]["team-members-group"]["display-ranking"]["raw"]) >= parseInt(b["toolset-meta"]["team-members-group"]["display-ranking"]["raw"])) {
                return 1;
            }
            if(parseInt(a["toolset-meta"]["team-members-group"]["display-ranking"]["raw"]) < parseInt(b["toolset-meta"]["team-members-group"]["display-ranking"]["raw"])) {
                return -1;
            }
            return 0;
        });
    };
    const fetchMember = () => {
        fetch('https://giulianogroup.com.au/wp-json/wp/v2/team-member/?per_page=30')
        .then(res => res.json())
        .then((json) => {
            json = sortJSON(json);
            const activeMember = json.filter((data) => { return data.status.includes('publish')});       
            setProfiles(activeMember);
            setLoaded(true);
        });
    }
    useEffect(() => {
        fetchMember();
    }, []);

    // const handleSearch = (query) => {
    //     if (query !== '') {           
    //         let filteredItems = fullList.current.filter((data) => {
    //             return data["toolset-meta"]["team-members-group"]["team-member-full-name"]["raw"].toLowerCase().includes(query.toLowerCase());
    //         });
            
    //         setProfiles(filteredItems);
    //     } else {
    //         setProfiles(fullList.current);
    //     }
    // }
    // const clearField = () => {
    //     document.getElementById("search-input").value = "";
    //     handleSearch("");
    // }

    if(!profiles.length) 
        return <div className='container loading-container'>
            <div className='loader'>
            </div>
        </div>;
    
    return (
        <div className="ebusinesscard-page">
            <div className="container">
                {profiles.map((item, key) => (
                    <TeamMember profile={item} key={key} />
                ))}
            </div>            
        </div>
    )
}
export default Homepage;