import React from "react";
import { GoogleMap, useJsApiLoader , Marker, InfoWindow } from "@react-google-maps/api";
import mapMaker from "../assets/img/marker.png";
const containerStyle = {
    width: '100%',
    height: '100%'
};
const center = {
    lat: -37.806763236470594,
    lng: 144.96214206946155
};

const mapOptions = { styles: [
    {
        "featureType": "landscape",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 65
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 51
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 30
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.local",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 40
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "lightness": -25
            },
            {
                "saturation": -100
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "hue": "#ffff00"
            },
            {
                "lightness": -25
            },
            {
                "saturation": -97
            }
        ]
    }
]};

function Map() {
    const [showInfo, setShowinfo] = React.useState(false);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAU0eE5HQ1vJLvJUC8JsyEwu1jGLEs4smY',
        lib: ["places"]
    });
    const [map, setMap] = React.useState(null);
    const [mapinfowindow, setMapinfowindow] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, []);
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    const showBubble = () => {
        setShowinfo(true);
    }
    const saveWindow = infoWindow => {
        setMapinfowindow(infoWindow);
    }
    if(!isLoaded) return <p>loading map...</p>
    
    return(        
        <>
        <div className="map-container">
            <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
            // onLoad={onLoad}
            onUnmount={onUnmount}
            options={mapOptions}
            >   
            
            <Marker icon={mapMaker} position={center} onClick={() => {setShowinfo(true)}} />
            {showInfo === true && (
            <InfoWindow position={center} onLoad={saveWindow} onCloseClick={() => {setShowinfo(false)}}>
                <div className="map-info">
                    <p>GIULIANO GROUP</p>
                    <p>17/501 Swanston Street, Melbourne VIC 3000</p>
                    <a target="blank" href="https://goo.gl/maps/2PHEfW92NEWckCiN7">Get directions</a>
                    </div>
            </InfoWindow>
            )}       
            </GoogleMap>
            </div>
        </>
    );
}
export default Map;