import React from "react";

function MyFooter() {
    let date = new Date();
    return (
        <>
        <div className="footer">   
            <p>Copyright {date.getFullYear()} Giuliano Group</p>
            <div className="footer-links">
                <ul>
                    <li><a href="https://giulianogroup.com.au/" target="_blank">Visit</a></li>
                    <li><a href="tel:+61396629996" target="_blank">Call</a></li>
                    <li><a href="https://www.instagram.com/giulianogroup/" target="_blank">Instagram</a></li>
                    <li><a href="https://www.linkedin.com/company/giuliano-group-australia/?originalSubdomain=au" target="_blank">LinkedIn</a></li>
                </ul>
            </div>
        </div>
        
        </>
    )
}

export default MyFooter;