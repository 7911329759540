import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Map from "../components/Map";
import shareIcon from "../assets/img/Share-Contact-Icon.png";
import plusIcon from "../assets/img/Plus-Icon.png";

function MemberDetail() {
    let params = useParams();
    let userId = params.userId;
    const [mem, setMem] = useState([]);
    const [Loaded, setLoaded] = useState(false);
    const getMember = () => {
        fetch('https://giulianogroup.com.au/wp-json/wp/v2/team-member/' + userId)
        .then(res => res.json())
        .then((res) => {
            setMem(res);
            setLoaded(true);
        });
    }
    useEffect(() => {
        getMember();
    }, []);
    if(!Loaded) {
        return (
            <div className='container loading-container'>
                <div className='loader'>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--text'></div>
                </div>
            </div>
        )
    }
    
    const copy = (e) => {
        e.preventDefault();
        const el = document.createElement('input');
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        // Show copied message
        let existingCopied = document.getElementById('copiedID');
        if(existingCopied) {
            existingCopied.remove();
        }
        const copied = document.createElement('p');
        copied.className = 'copied';
        copied.id = 'copiedID';
        copied.innerHTML = 'URL copied to clipboard!';
        document.getElementById('share-wrapper').appendChild(copied);
    }
    const memberFields = mem['toolset-meta']['team-members-group'];
    const photo = memberFields['team-member-listing-photo']['raw'];
    const backgroundStyle = {
        backgroundImage: 'url('+photo+')'
    }
    const email = memberFields['email']['raw'];
    let firstPart = "";
    let secondPart = "";
    let atIndex = "";
    if(email.length > 35) {
        atIndex = email.indexOf('@');
        firstPart = email.slice(0, atIndex);
        secondPart = email.slice(atIndex);
    }
    return (
        <>
            <div className="container">
                <div className="team-member-detail-wrapper">               
                    <div className="photo" style={backgroundStyle} ></div>
                    <div className="ipad-wrap">
                        <div className="info-wrap">
                            <h1 className="name">{memberFields['team-member-full-name']['raw']}</h1>
                            <p className="title">{memberFields['job-title']['raw']}</p>
                            <p><span>T</span>{memberFields['team-member-phone']['raw']}</p>
                            {atIndex
                                ? <p className="long-email"><span>E</span>{firstPart}<br className="mobile-only"></br>{secondPart}</p>  
                                : <p><span>E</span>{memberFields['email']['raw']}</p>  
                            }
                        </div>
                        <div className="all-buttons-wrap">
                            <div className="cta-wrap-2" id="share-wrapper">
                                <a className="clear-button" href={'https://giulianogroup.com.au/wp-json/businesscards/v1/vcard/'+userId} >Add to contact <img src={plusIcon} /></a>
                                <a className="clear-button" href="" onClick={copy}>Share contact <img src={shareIcon} /></a>
                            </div>
                            <div className="cta-wrap">
                                <a href={"tel:"+memberFields['team-member-phone']['raw']}>Call</a>
                                <a href={"mailto:"+memberFields['email']['raw']}>Email</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Map />
                <a className="back-button" href="/"><span>&lt;</span> <p>Back</p></a>
            </div>
        </>
    )
}
export default MemberDetail;