import React from "react";
import logo from "../assets/img/GG_LOGO.png";
export default function MyHeader() {
    return (
        <div className="header">
            <div className="container">
                <img src={logo} alt="logo" />
            </div>
        </div>
    )
}